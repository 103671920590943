<template>
  <a-page-header
    @back="() => $router.go(-1)"
    title="GRÁFICAS POR ESTABLECIMIENTO"
    class="pt-0 px-0"
  >
    <template #extra>
      <a-breadcrumb>
        <a-breadcrumb-item><router-link to="/menu">Proyecto</router-link></a-breadcrumb-item>
        <a-breadcrumb-item><router-link to="/indicador">Indicador</router-link></a-breadcrumb-item>
        <a-breadcrumb-item
          ><router-link :to="'/indicador/' + idindicador">Resumen</router-link></a-breadcrumb-item
        >
        <a-breadcrumb-item>Establecimiento</a-breadcrumb-item>
      </a-breadcrumb>
    </template>
  </a-page-header>

  <div class="flex gap-2 mb-4 md:gap-3 lg:gap-4">
    <div class="w-full border bg-white font-semibold">
      <p class="text-black">
        <span class="bg-green-600 py-2 px-6 text-white"
          >Indicador: {{ indicadorData.abreviatura }}
        </span>
      </p>
      <p class="px-4 py-2 lg:text-lg lg:p-4">
        {{ indicadorData.denominacion }}
      </p>
    </div>
  </div>

  <div class="flex flex-wrap">
    <fieldset class="border border-solid border-gray-300 p-3 px-8 w-full lg:w-auto">
      <legend class="text-sm pl-4">Filtra el indicador</legend>
      <IndicadorGraficasEstablecimientoFiltros
        @changedistrito="changedistrito"
        @changePeriodo="changePeriodo"
      />
    </fieldset>

    <fieldset class="border border-solid border-gray-300 p-3 px-8 ml-5">
      <legend class="text-sm pl-4">Acciones</legend>

      <button @click="showModalPrintModal" class="text-center ml-4">
        <div class="text-3xl" :class="'text-red-400'">
          <FilePdfOutlined />
        </div>
        <p>
          Visualizar<br />
          PDF
        </p>
      </button>
    </fieldset>
  </div>

  <div class="w-full flex flex-wrap lg:flex-nowrap gap-4 mt-5">
    <div class="flex flex-col w-full lg:w-1/2 gap-4">
      <a-card size="small" class="relative">
        <template #title>
          <h1 class="text-center font-semibold text-lg">PORCENTAJE DE AVANCE</h1>
        </template>
        <v-chart :option="grafica.gauge.options" style="width: 100%; height: 300px" />

        <div class="flex justify-center gap-6 absolute bottom-5 lg:left-1/3">
          <span class="flex items-center"
            >Bajo &nbsp;
            <div class="w-4 h-4 bg-red-500"></div
          ></span>
          <span class="flex items-center"
            >Medio &nbsp;
            <div class="w-4 h-4 bg-yellow-500"></div
          ></span>
          <span class="flex items-center"
            >Alto &nbsp;
            <div class="w-4 h-4 bg-green-500"></div
          ></span>
        </div>
      </a-card>

      <a-card size="small">
        <template #title>
          <h1 class="text-center font-semibold text-lg">MICRORED TACNA</h1>
        </template>
        <v-chart :option="grafica.pie.options" style="width: 100%; height: 300px" />
      </a-card>
    </div>

    <div class="flex flex-col w-full lg:w-1/2 gap-4">
      <a-card size="small">
        <template #title>
          <h1 class="text-center font-semibold text-lg">PORCENTAJE POR MESES</h1>
        </template>
        <v-chart :option="grafica.line.options" style="width: 100%; height: 300px" />
      </a-card>

      <a-card size="small">
        <template #title>
          <h1 class="text-center font-semibold text-lg">DETALLE POR MESES</h1>
        </template>
        <v-chart :option="grafica.barLabel.options" style="width: 100%; height: 300px" />
      </a-card>
    </div>
  </div>

  <div class="w-full">
    <a-card size="small">
      <template #title>
        <h1 class="text-center font-semibold text-lg">ESTABLECIMIENTOS</h1>
      </template>
      <v-chart :option="grafica.barRainfall.options" :style="{ height: height + 'px' }" />
    </a-card>
  </div>

  <a-modal
    :visible="isVisiblePrintModal"
    @close="closeModalPrintModal"
    @cancel="closeModalPrintModal"
    :footer="null"
    class="max-w-sm md:max-w-5xl text-center"
    width="100%"
  >
    <a-button
      shape="round"
      v-print="objPrintIndicadorResumen"
      size="large"
      class="text-center mx-auto mb-8 bg-red-500 text-white"
    >
      <template #icon>
        <PrinterOutlined />
        Imprimir
      </template>
    </a-button>

    <div id="printIndicadorGraficasEstablecimiento">
      <PrintIndicadorGraficasEstablecimiento>
        <template #header>
          <div class="flex w-full">
            <div class="flex w-2/12">
              <h1 class="border p-1 text-xs w-full font-semibold bg-gray-200">FECHA INICIO</h1>
              <p class="border p-1 w-full">{{ formFilters.periodo_inicio }}</p>
            </div>

            <div class="flex w-2/12">
              <h1 class="border p-1 text-xs w-full font-semibold bg-gray-200">FECHA FIN</h1>
              <p class="border p-1 w-full">{{ formFilters.periodo_fin }}</p>
            </div>

            <div class="flex w-4/12">
              <h1 class="border p-1 text-xs w-full font-semibold bg-gray-200">MICRORED</h1>
              <p class="border p-1 w-full">{{ formFilters.microred }}</p>
            </div>

            <div class="flex w-4/12">
              <h1 class="border p-1 text-xs w-full font-semibold bg-gray-200">ESTABLECIMIENTO</h1>
              <p class="border p-1 w-full">{{ formFilters.establecimiento }}</p>
            </div>
          </div>
        </template>
        <template #grafico>
          <div class="w-full flex flex-wrap lg:flex-nowrap gap-4 mt-5">
            <div class="flex flex-col w-full lg:w-1/2 gap-4">
              <a-card size="small" class="relative">
                <template #title>
                  <h1 class="text-center font-semibold text-lg">PORCENTAJE DE AVANCE</h1>
                </template>
                <v-chart :option="grafica.gauge.options" style="width: 100%; height: 300px" />

                <div class="flex justify-center gap-6 absolute bottom-5 lg:left-1/3">
                  <span class="flex items-center"
                    >Bajo &nbsp;
                    <div class="w-4 h-4 bg-red-500"></div
                  ></span>
                  <span class="flex items-center"
                    >Medio &nbsp;
                    <div class="w-4 h-4 bg-yellow-500"></div
                  ></span>
                  <span class="flex items-center"
                    >Alto &nbsp;
                    <div class="w-4 h-4 bg-green-500"></div
                  ></span>
                </div>
              </a-card>

              <a-card size="small">
                <template #title>
                  <h1 class="text-center font-semibold text-lg">MICRORED TACNA</h1>
                </template>
                <v-chart :option="grafica.pie.options" style="width: 100%; height: 300px" />
              </a-card>
            </div>

            <div class="flex flex-col w-full lg:w-1/2 gap-4">
              <a-card size="small">
                <template #title>
                  <h1 class="text-center font-semibold text-lg">PORCENTAJE POR MESES</h1>
                </template>
                <v-chart :option="grafica.line.options" style="width: 100%; height: 300px" />
              </a-card>

              <a-card size="small">
                <template #title>
                  <h1 class="text-center font-semibold text-lg">DETALLE POR MESES</h1>
                </template>
                <v-chart :option="grafica.barLabel.options" style="width: 100%; height: 300px" />
              </a-card>
            </div>
          </div>
        </template>
      </PrintIndicadorGraficasEstablecimiento>
    </div>
  </a-modal>
</template>

<script>
import { ref, toRaw, onMounted, reactive, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import GraficasApi from "@/api/graficas";
import IndicadorGraficasEstablecimientoFiltros from "./IndicadorGraficasEstablecimientoFiltros.vue";
import { FilePdfOutlined } from "@ant-design/icons-vue";
import { calculaPorcentaje, semaforoPorcentajeIndicadorHex } from "@/utils";
import ApiIndicador from "@/api/indicador";
import PrintIndicadorGraficasEstablecimiento from "@/print/PrintIndicadorGraficasEstablecimiento.vue";

import {
  optionsGauge,
  optionsPie,
  optionsBar,
  optionsLine,
  optionsBarLabel
} from "./utilsIndicadorGraficaEstablecimiento";

export default {
  components: {
    FilePdfOutlined,
    IndicadorGraficasEstablecimientoFiltros,
    PrintIndicadorGraficasEstablecimiento
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const height = ref("750");
    const RANGO_BAJO = ref(30);
    const RANGO_MEDIO = ref(60);
    const porcentajeColor = ref("");
    const isVisiblePrintModal = ref(false);
    const formFilters = reactive({
      periodo_inicio: "TODOS",
      periodo_fin: "TODOS",
      microred: "TODAS",
      establecimiento: route.params?.idestablecimiento || "TODOS"
    });

    watch(() => {
      const periodoinicio = store.getters["graficasEstablecimientos/periodo_ini"];
      const periodo_inicio_formateado = periodoinicio.slice(0, 4) + "." + periodoinicio.slice(4);

      const periodofin = store.getters["graficasEstablecimientos/periodo_fin"];
      const periodo_fin_formateado = periodofin.slice(0, 4) + "." + periodofin.slice(4);

      formFilters.periodo_inicio = periodo_inicio_formateado || "TODOS";
      formFilters.periodo_fin = periodo_fin_formateado || "TODOS";
      formFilters.microred = store.getters["graficasEstablecimientos/microred"] || "TODAS";
      formFilters.establecimiento = route.params?.idestablecimiento || "TODOS";
    });

    const indicadorData = reactive({
      denominacion: "",
      abreviatura: ""
    });
    const grafica = reactive({
      gauge: {
        options: null
      },
      pie: {
        options: null
      },
      barRainfall: {
        options: null
      },
      line: {
        options: null
      },
      barLabel: {
        options: null
      }
    });

    const meses = [
      "ENE",
      "FEB",
      "MAR",
      "ABR",
      "MAY",
      "JUN",
      "JUL",
      "AGO",
      "SET",
      "OCT",
      "NOV",
      "DIC"
    ];

    const objPrintIndicadorResumen = reactive({
      id: "printIndicadorGraficasEstablecimiento",
      popTitle: "SIVINUR | GRÁFICAS POR ESTABLECIMIENTO"
    });

    const idindicador = route.params?.indicador;

    const getInfoIndicador = () => {
      ApiIndicador.getInfo(idindicador)
        .then((res) => {
          indicadorData.denominacion = res.data[0].denominacion;
          indicadorData.abreviatura = res.data[0].abreviatura;
        })
        .catch((error) => console.log(error));
    };

    const fetchSemaforoIndicador = () => {
      ApiIndicador.getPorcentajeColor(toRaw(idindicador))
        .then((res) => {
          RANGO_BAJO.value =
            res.data.find((parametro) => parametro.clave === "RANGO_BAJO")?.valor || 30;
          RANGO_MEDIO.value =
            res.data.find((parametro) => parametro.clave === "RANGO_MEDIO")?.valor || 60;
        })
        .catch((err) => {
          console.log(err);
        });
    };

    onMounted(() => {
      var data = route.params?.idestablecimiento;
      store.dispatch("graficasEstablecimientos/setEstablecimiento", data);

      getInfoIndicador();
      fetchSemaforoIndicador();
    });

    const getAllByGeneral = () => {
      const sigla = store.getters["mapa/sigla"];
      const microred = store.getters["graficasEstablecimientos/microred"];
      const establecimiento = store.getters["graficasEstablecimientos/establecimiento"];
      const periodo_ini = store.getters["graficasEstablecimientos/periodo_ini"];
      const periodo_fin = store.getters["graficasEstablecimientos/periodo_fin"];

      GraficasApi.getAllByGeneralEstablecimiento(
        sigla,
        microred,
        establecimiento,
        periodo_ini,
        periodo_fin
      )
        .then((response) => {
          const porcentaje = calculaPorcentaje(
            response.data[0].total_numerador,
            response.data[0].total_denominador
          );

          porcentajeColor.value = semaforoPorcentajeIndicadorHex(
            RANGO_BAJO.value,
            RANGO_MEDIO.value,
            porcentaje
          );

          grafica.gauge.options = optionsGauge(porcentaje, porcentajeColor);
        })
        .catch((err) => console.log(err));
    };

    const getAllByProvincia = () => {
      const sigla = store.getters["mapa/sigla"];
      const microred = store.getters["graficasEstablecimientos/microred"];
      const periodo_ini = store.getters["graficasEstablecimientos/periodo_ini"];
      const periodo_fin = store.getters["graficasEstablecimientos/periodo_fin"];

      GraficasApi.getAllByMicrored(sigla, microred, periodo_ini, periodo_fin)
        .then((response) => {
          const pieData = [];
          // microred

          response.data.forEach((element) => {
            pieData.push({
              value: (
                (element.total_numerador_microred / element.total_denominador_microred) *
                100
              ).toFixed(2),
              name: element.microred
            });
          });

          grafica.pie.options = optionsPie(pieData);
        })
        .catch((err) => console.log(err));
    };

    const getAllByDistritos = () => {
      const sigla = store.getters["mapa/sigla"];
      const microred = store.getters["graficasEstablecimientos/microred"];
      const establecimiento = store.getters["graficasEstablecimientos/establecimiento"];
      const periodo_ini = store.getters["graficasEstablecimientos/periodo_ini"];
      const periodo_fin = store.getters["graficasEstablecimientos/periodo_fin"];

      GraficasApi.getAllByEstablecimiento(
        sigla,
        microred,
        periodo_ini,
        periodo_fin,
        establecimiento
      )
        .then((response) => {
          const distrito = [];
          const total_denominador_distrito = [];
          const total_numerador_distrito = [];
          response.data.forEach((element) => {
            distrito.push(element.establecimiento);
            total_numerador_distrito.push(element.total_numerador_establecimiento);
            total_denominador_distrito.push(element.total_denominador_establecimiento);
          });

          grafica.barRainfall.options = optionsBar(
            distrito,
            total_numerador_distrito,
            total_denominador_distrito
          );
        })
        .catch((err) => console.log(err));
    };

    const getAllByPeriodo = () => {
      const sigla = store.getters["mapa/sigla"];
      const microred = store.getters["graficasEstablecimientos/microred"];
      const establecimiento = store.getters["graficasEstablecimientos/establecimiento"];
      const periodo_ini = store.getters["graficasEstablecimientos/periodo_ini"];
      const periodo_fin = store.getters["graficasEstablecimientos/periodo_fin"];

      GraficasApi.getAllByPeriodoEstablecimiento(
        sigla,
        microred,
        establecimiento,
        periodo_ini,
        periodo_fin
      )
        .then((response) => {
          const idperiodo = [];
          const porcentaje = [];
          const total_numerador_periodo = [];
          const total_denominador_periodo = [];
          response.data.forEach((element) => {
            const periodo =
              element.idperiodo.substr(4, 6) < 10
                ? element.idperiodo.substr(4, 6)[1] - 1
                : element.idperiodo.substr(4, 6) - 1;

            idperiodo.push(meses[periodo] + "-" + element.idperiodo.substr(0, 4));
            total_numerador_periodo.push(element.total_numerador_establecimiento);
            total_denominador_periodo.push(element.total_denominador_establecimiento);

            porcentaje.push(
              (
                (element.total_numerador_establecimiento /
                  element.total_denominador_establecimiento) *
                100
              ).toFixed(2)
            );
          });

          grafica.line.options = optionsLine(idperiodo, porcentaje);
          grafica.barLabel.options = optionsBarLabel(
            idperiodo,
            total_numerador_periodo,
            total_denominador_periodo
          );
        })
        .catch((err) => console.log(err));
    };

    const changedistrito = () => {
      getAllByGeneral();
      getAllByProvincia();
      getAllByDistritos();
      getAllByPeriodo();
    };

    const changePeriodo = () => {
      getAllByGeneral();
      getAllByProvincia();
      getAllByDistritos();
      getAllByPeriodo();
    };

    const showModalPrintModal = () => {
      isVisiblePrintModal.value = true;
    };
    const closeModalPrintModal = () => {
      isVisiblePrintModal.value = false;
    };

    return {
      isVisiblePrintModal,
      showModalPrintModal,
      closeModalPrintModal,
      objPrintIndicadorResumen,
      changedistrito,
      changePeriodo,
      indicadorData,
      idindicador,
      grafica,
      height,

      formFilters
    };
  }
};
</script>

<style></style>
