/**
 * optionsGauge
 * ===========
 * Muestra en porcentaje y lo pinta
 * de acuerdo a su valor de 0 - 100
 * @param {Number} percentValue
 * @returns {Object} formato de echarts
 * https://echarts.apache.org/examples/en/editor.html?c=gauge-progress
 */
export const optionsGauge = (percentValue = 50, porcentajeColor = "#000") => {
  /**
   * getColor
   * @param {Number} dataValue
   * from green to red color depend on percentage
   * https://stackoverflow.com/a/17268489/9868383
   */
  // function getColor(dataValue) {
  //   const value = 100 - dataValue / 100;
  //   //value from 0 to 1
  //   var hue = ((1 - value) * 120).toString(10);
  //   return ["hsl(", hue, ",100%,50%)"].join("");
  // }

  return {
    // title: {
    //   text: "% Avance",
    //   left: "center"
    // },
    series: [
      {
        type: "gauge",
        startAngle: 180,
        endAngle: 0,
        min: 0,
        max: 100,
        splitNumber: 2,
        itemStyle: {
          color: porcentajeColor
        },
        progress: {
          show: true,
          roundCap: true,
          width: 18
        },
        pointer: {
          show: false
        },
        detail: {
          offsetCenter: [0, "0%"],
          valueAnimation: true,
          formatter: function (value) {
            return value + " %";
          },
          color: "auto"
        },
        data: [
          {
            value: percentValue
          }
        ]
      }
    ]
  };
};

export const provinciaGraphic = (barData = []) => {
  const object = {
    xAxis: {
      type: "category",
      data: barData.map((p) => p.name)
    },
    yAxis: {
      type: "value"
    },
    series: [
      {
        label: {
          show: true
        },
        data: barData.map((p) => p.value),
        type: "bar"
      }
    ]
  };

  return object;
};

/**
 * optionsPie
 * ==========
 * Genera los puntos para grafico Pie
 * @param {Array} data
 * @returns {Object} formato de echarts
 * https://echarts.apache.org/examples/en/editor.html?c=pie-simple
 */
export const optionsPie = (data = []) => {
  return {
    // title: {
    //   text: "Microred Tacna",
    //   left: "center"
    // },
    tooltip: {
      trigger: "item"
    },
    legend: {
      orient: "vertical",
      left: "left"
    },
    series: [
      {
        name: "Microred",
        type: "pie",
        radius: "50%",
        label: {
          formatter: "{c}",
          position: "inside",
          fontSize: 15
        },
        data,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)"
          }
        }
      }
    ]
  };
};

/**
 * optionsBar - barRainfall
 * =========================
 * @returns {Object} formato de echarts
 * https://echarts.apache.org/examples/en/editor.html?c=bar1
 */
export const optionsBar = (distritos = [], numerador = [], denominador = []) => {
  return {
    // title: {
    //   text: "Establecimientos",
    //   subtext: "Numerador/Denominador"
    // },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow"
      }
    },
    legend: {
      data: ["Numerador", "Denominador"],
      bottom: "bottom"
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true
    },
    xAxis: {
      type: "value",
      boundaryGap: [0, 0.01]
    },
    yAxis: {
      type: "category",
      data: distritos
    },
    series: [
      {
        name: "Numerador",
        type: "bar",
        data: numerador,
        markPoint: {
          data: [
            { type: "max", name: "Max" },
            { type: "min", name: "Min" }
          ]
        },
        markLine: {
          data: [{ type: "average", name: "Avg" }]
        }
      },
      {
        name: "Denominador",
        type: "bar",
        data: denominador,
        markPoint: {
          data: [
            { name: "Max", value: 182.2, xAxis: 7, yAxis: 183 },
            { name: "Min", value: 2.3, xAxis: 11, yAxis: 3 }
          ]
        },
        markLine: {
          data: [{ type: "average", name: "Avg" }]
        }
      }
    ]
  };
};

export const optionsLine = (meses = [], porcentajes = []) => {
  return {
    // title: {
    //   text: "Meses %"
    // },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#6a7985"
        }
      }
    },
    legend: {
      data: ["Porcentaje"]
    },
    toolbox: {
      feature: {
        saveAsImage: {}
      }
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true
    },
    xAxis: [
      {
        type: "category",
        boundaryGap: false,
        data: meses
      }
    ],
    yAxis: [
      {
        type: "value"
      }
    ],
    series: [
      {
        name: "Porcentaje",
        type: "line",
        stack: "Total",
        areaStyle: {},
        emphasis: {
          focus: "series"
        },
        label: {
          show: true,
          position: "top"
        },
        data: porcentajes
      }
    ]
  };
};

/**
 * barLabel
 * @returns
 * https://echarts.apache.org/examples/en/editor.html?c=bar-label-rotation
 */
export const optionsBarLabel = (meses = [], numerador = [], denominador = []) => {
  return {
    // title: {
    //   text: "Meses",
    //   subtext: "Numerador/Denominador"
    // },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow"
      }
    },
    legend: {
      data: ["Numerador", "Denominador"],
      bottom: "bottom"
    },
    toolbox: {
      show: true,
      orient: "vertical",
      left: "right",
      top: "center",
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: false },
        magicType: { show: true, type: ["line", "bar", "stack"] },
        restore: { show: true },
        saveAsImage: { show: true }
      }
    },
    xAxis: [
      {
        type: "category",
        axisTick: { show: false },
        data: meses
      }
    ],
    yAxis: [
      {
        type: "value"
      }
    ],
    series: [
      {
        name: "Numerador",
        type: "bar",
        barGap: 0,
        // label: labelOption,
        emphasis: {
          focus: "series"
        },
        data: numerador
      },
      {
        name: "Denominador",
        type: "bar",
        // label: labelOption,
        emphasis: {
          focus: "series"
        },
        data: denominador
      }
    ]
  };
};
