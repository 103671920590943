<template>
  <PrintContainer tituloReporte="GRÁFICAS POR ESTABLECIMIENTO">
    <slot name="header"></slot>

    <slot name="grafico"></slot>
  </PrintContainer>
</template>

<script>
import PrintContainer from "./PrintContainer.vue";
import { calculaPorcentaje } from "@/utils";

import { ref } from "vue";
import { useStore } from "vuex";

export default {
  components: {
    PrintContainer
  },
  props: {
    indicadorEstablecimientos: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    const store = useStore();

    const search = ref(store.getters["indicador/search"]);
    const filter = ref(store.getters["indicador/filter"]);

    return {
      search,
      filter,
      calculaPorcentaje
    };
  }
};
</script>

<style scoped>
table {
  page-break-inside: auto;
  page-break-before: avoid;
}
tr {
  page-break-inside: avoid;
  page-break-after: auto;
}
thead {
  display: table-header-group;
}
tfoot {
  display: table-footer-group;
}
</style>
