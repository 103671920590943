<template>
  <a-form :model="formState" class="flex flex-col md:flex-row md:gap-5">
    <a-form-item label="FECHA INICIO" name="periodo" class="my-1 inline-block md:w-52">
      <a-select v-model:value="formState.idperiodo_ini" @select="onSelectPeriodoIni">
        <a-select-option value="">TODOS</a-select-option>
        <a-select-option
          v-for="periodo in periodos"
          :value="periodo.idperiodo"
          :key="periodo.idperiodo"
        >
          {{ periodo.denominacion }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item label="FECHA FIN" name="periodo" class="my-1 inline-block md:w-52">
      <a-select v-model:value="formState.idperiodo_fin" @select="onSelectPeriodoFin">
        <a-select-option value="">TODOS</a-select-option>
        <a-select-option
          v-for="periodo in periodos"
          :value="periodo.idperiodo"
          :key="periodo.idperiodo"
        >
          {{ periodo.denominacion }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item label="MICRORED" name="provincia" class="my-1 inline-block md:w-52">
      <a-select v-model:value="formState.microred" @select="onSelectProvincia">
        <a-select-option key="ALL" value="">TODAS</a-select-option>
        <a-select-option v-for="p in provincias" :value="p.microred" :key="p.microred">
          {{ p.microred }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item label="ESTABLECIMIENTO" name="distrito" class="my-1 inline-block md:w-52">
      <a-select v-model:value="formState.establecimiento" @select="onSelectDistrito">
        <a-select-option key="ALL" value="">TODOS</a-select-option>
        <a-select-option v-for="d in distritos" :value="d.establecimiento" :key="d.establecimiento">
          {{ d.establecimiento }}
        </a-select-option>
      </a-select>
    </a-form-item>
  </a-form>
</template>

<script>
import { ref, reactive, onMounted, watch } from "vue";
import IndicadorApi from "@/api/indicador";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import GraficasApi from "@/api/graficas";

export default {
  props: {
    sigla: {
      type: String
    }
  },
  emits: ["changedistrito", "changePeriodo", "changeprovincia"],
  setup(props, { emit }) {
    const store = useStore();
    const periodos = ref([]);
    const provincias = ref([]);
    const distritos = ref([]);
    const route = useRoute();

    const formState = reactive({
      idperiodo_ini: store.getters["graficasEstablecimientos/periodo_ini"] || "",
      idperiodo_fin: store.getters["graficasEstablecimientos/periodo_fin"] || "",
      microred: store.getters["graficasEstablecimientos/microred"] || "",
      establecimiento: route.params?.idestablecimiento || ""
    });

    const fetchPeriodos = (periodo, idindicador) => {
      IndicadorApi.getperiodoByByIDIndicador(idindicador)
        .then((response) => {
          periodos.value = response.data;
        })
        .catch((err) => console.log(err));
    };

    const fetchProvincias = () => {
      GraficasApi.getAllMicrored("ind_registro_fcg4")
        .then((response) => {
          provincias.value = response.data;
        })
        .catch((err) => console.log(err));
    };

    const fetchDistritos = (microred = "") => {
      GraficasApi.getAllEstablecimiento("ind_registro_fcg4", microred)
        .then((response) => {
          distritos.value = response.data;
        })
        .catch((err) => console.log(err));
    };

    onMounted(async () => {
      const periodo = store.getters["mapa/periodo"];
      const idindicador = route.params?.indicador;

      const response = await GraficasApi.getAllSiglaIndicador(idindicador);
      store.dispatch("mapa/setSigla", response.data[0].sigla);

      if (periodo > 0) {
        await GraficasApi.getAllPeriodoEvaluacion(periodo);
      }

      emit("changedistrito");
      emit("changePeriodo");

      fetchPeriodos(periodo, idindicador);
      fetchProvincias();
      fetchDistritos();
    });

    watch(formState, () => {
      console.log(formState);
    });

    const onSelectProvincia = (microred) => {
      store.dispatch("graficasEstablecimientos/setEstablecimiento", "");
      formState.establecimiento = "";

      store.dispatch("graficasEstablecimientos/setMicrored", microred);
      emit("changedistrito");
      fetchDistritos(microred);
    };

    const onSelectPeriodoIni = (periodo) => {
      store.dispatch("graficasEstablecimientos/setPeriodoIni", periodo);
      emit("changePeriodo");
    };

    const onSelectPeriodoFin = (periodo) => {
      store.dispatch("graficasEstablecimientos/setPeriodoFin", periodo);
      emit("changePeriodo");
    };

    const onSelectDistrito = (establecimiento) => {
      store.dispatch("graficasEstablecimientos/setEstablecimiento", establecimiento);
      emit("changedistrito");
    };

    const cleanFilters = () => {
      formState.idperiodo = "";
      formState.microred = "";
      formState.establecimiento = "";

      store.dispatch("graficasEstablecimientos/setMicrored", "");
      store.dispatch("graficasEstablecimientos/setPeriodoIni", "");
      store.dispatch("graficasEstablecimientos/setPeriodoFin", "");
      store.dispatch("graficasEstablecimientos/setEstablecimiento", "");

      emit("changedistrito");
      emit("changePeriodo");
    };

    return {
      formState,
      periodos,
      provincias,
      distritos,
      onSelectPeriodoIni,
      onSelectPeriodoFin,
      onSelectProvincia,
      onSelectDistrito,
      cleanFilters
    };
  }
};
</script>

<style scoped></style>
